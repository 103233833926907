import React, { createContext, useCallback, useContext, useState } from 'react'
import { IChatMessage, IChatV2 } from '@/models/IChatV2'
import { useAuthContext } from '../Auth'

interface MessagesContextInterface {
  itemsByChat: Record<string, IChatMessage[]>
  chatInfoById: Record<string, IChatV2>
  onChatInfo: (chatId: string, v: IChatV2) => void
  onMessages: (
    chatId: string,
    items: (IChatMessage & { showSkeleton?: boolean })[],
  ) => void
  onNextMessages: (
    chatId: string,
    items: (IChatMessage & { showSkeleton?: boolean })[],
  ) => void
  onNewMessage: (m: IChatMessage) => void
  onMessageDelete: (m: IChatMessage) => void
  onMessageUpdate: (m: IChatMessage) => void
  onArchivedChatRestore: (id: string) => void
  onChatRequest: (data: { count: number; title: string }) => void
  onNewChat: (chat: IChatV2) => void
  onAcceptRequest: (title: string) => void
  onChatUpdate: (id: string, value: IChatV2) => void
  removeChat: (chatId: string) => void
  items: IChatV2[]
  requests: { count: number; title: string }
}
export const MessagesContext = createContext<MessagesContextInterface>(
  {} as MessagesContextInterface,
)

export const MessagesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authContext = useAuthContext()

  // eslint-disable-next-line no-spaced-func
  const [itemsByChat, setItemsByChat] = useState<
    Record<string, (IChatMessage & { showSkeleton?: boolean })[]>
  >({})
  const [requests, setRequests] = useState<{ count: number; title: string }>({
    count: 0,
    title: '',
  })
  const [chatInfoById, setChatInfoById] = useState<Record<string, IChatV2>>({})
  const [items, setItems] = useState<IChatV2[]>([])
  const mapItems = useCallback(
    (itemsToMap: IChatV2[]) => {
      return itemsToMap.map((item) => ({
        ...item,
        participants:
          item.type === 'private'
            ? item.participants.filter(
                ({ userId }) => userId !== authContext.user?.id,
              )
            : item.participants,
      }))
    },
    [authContext.user?.id],
  )

  const mapMessages = (messages: IChatMessage[] = []) =>
    messages.map((message) => {
      return {
        ...message,
        arrived: message.arrived ?? true,
      }
    })

  const onChatInfo = (chatId: string, chatInfo: IChatV2) => {
    setChatInfoById((prev) => ({
      ...prev,
      [chatId]: {
        ...(prev[chatId] ?? {}),
        ...chatInfo,
        participants:
          chatInfo.type === 'private'
            ? chatInfo.participants.filter(
                ({ userId }) => userId !== authContext.user?.id,
              )
            : chatInfo.participants,
        otherParticipant:
          chatInfo.type === 'private'
            ? chatInfo.participants.find(
                (participant) => participant.userId !== authContext.user?.id,
              )
            : undefined,
        privateOtherUser:
          chatInfo.type === 'private'
            ? chatInfo.participants.filter(
                ({ userId }) => userId !== authContext.user?.id,
              )
            : undefined,
        requstStatus:
          chatInfo.type === 'private' || chatInfo.type === 'privateGroup'
            ? chatInfo.participants.filter(
                ({ userId }) => userId == authContext.user?.id,
              )
            : undefined,
      },
    }))
  }

  const onMessages = (chatId: string, messages: IChatMessage[]) => {
    setItemsByChat((prev) => ({
      ...prev,
      [chatId]: mapMessages(messages),
    }))
  }

  const onNextMessages = (chatId: string, messages: IChatMessage[]) => {
    setItemsByChat((prev) => ({
      ...prev,
      [chatId]: mapMessages(prev[chatId].concat(messages)),
    }))
  }

  const onNewMessage = (message: IChatMessage) => {
    setItemsByChat((prev) => ({
      ...prev,
      [message.chatId]: prev[message.chatId]
        ? mapMessages([
            message,
            ...prev[message.chatId].filter(({ id }) => id !== message.id),
          ])
        : [message],
    }))
  }

  const onMessageUpdate = (parsed: IChatMessage) => {
    setItemsByChat((prev) => ({
      ...prev,
      ...(prev[parsed.chatId]
        ? {
            [parsed.chatId]: prev[parsed.chatId].map((item) =>
              item.id === parsed.id ? parsed : item,
            ),
          }
        : {}),
    }))
  }

  const onMessageDelete = (parsed: IChatMessage) => {
    setItemsByChat((prev) => ({
      ...prev,
      [parsed.chatId]: prev[parsed.chatId]?.length
        ? mapMessages([
            ...prev[parsed.chatId].filter(({ id }) => id !== parsed.id),
          ])
        : [],
    }))
  }

  const onArchivedChatRestore = (id: string) => {
    setChatInfoById((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        archived: false,
      },
    }))
  }
  // const onChatRequest = (data: { count: number; title: string }) => {
  //   setRequests((prevRequests) => ({
  //     ...prevRequests,
  //     [data.title]: { count: data.count, title: data.title },
  //   }))
  // }
  const onChatRequest = (data: { count: number; title: string }) => {
    setRequests({
      count: data.count,
      title: data.title,
    })
  }

  const onNewChat = (chat: IChatV2) => {
    setItems((prev) => [chat].concat(prev.filter(({ id }) => id !== chat.id)))
  }

  const onAcceptRequest = (title: string) => {
    setRequests((prev) => {
      if (!prev || prev.title !== title) return prev

      const newRequests = {
        ...prev,
        count: prev.count > 0 ? prev.count - 1 : prev.count,
      }

      return newRequests
    })
  }

  const onChatUpdate = (id: string, value: IChatV2) => {
    setItems((prev) =>
      mapItems([
        {
          ...(prev.find((item) => item.id === id) || {}),
          ...value,
          unreadCount:
            value.lastMessageUser === authContext.user?.id
              ? 0
              : (prev.find((item) => item.id === id)?.unreadCount || 0) + 1,
        },
        ...prev.filter((item) => item.id !== id),
      ]),
    )
  }

  const removeChat = useCallback((chatId: string) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.filter((chat) => chat.id !== chatId)
      return updatedItems
    })
  }, [])

  return (
    <MessagesContext.Provider
      value={{
        itemsByChat,
        chatInfoById,
        onChatInfo,
        onMessages,
        onNewMessage,
        onNextMessages,
        onMessageDelete,
        onMessageUpdate,
        onArchivedChatRestore,
        onChatRequest,
        requests,
        onNewChat,
        onAcceptRequest,
        onChatUpdate,
        removeChat,
        items,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessagesContext = (): MessagesContextInterface =>
  useContext(MessagesContext)
