import { AxiosInstance } from 'axios'
import { IChatV2, ChatResponse, ReportReason } from '@/models/IChatV2'
import { IPagination } from '@/models/IPagination'
import { IUser } from '@/models/IUser'

export class InboxV2Service {
  constructor(private instance: AxiosInstance) {}

  getNewChatUsers = (params: {
    text?: string
    limit?: number
    token?: string
    excludeChatId?: string
  }) =>
    this.instance
      .get<{
        items: any
        users: IUser[]
        pagination: IPagination
      }>('/chats-v2/new/users', { params })
      .then((response) => response.data)

  createChat = (body: {
    fromChatId?: string
    participants: string[]
  }): Promise<IChatV2> =>
    this.instance
      .post<IChatV2>(`/chats-v2/create`, body)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error creating chat:', error)
        throw error
      })

  getChats = (params: {
    search?: string
    limit?: number
    status?: 'pending' | 'accepted' | 'archived' | 'declined'
    token?: string
  }) =>
    this.instance
      .get<ChatResponse>('/chats-v2', { params })
      .then((response) => response.data)

  acceptRequest = (chatId: string) =>
    this.instance
      .post(`/participant-status/${chatId}/accept`, {})
      .then((response) => response)

  archiveChat = (id: string) =>
    this.instance
      .post<{ success: boolean }>(`/chats-v2/${id}/archive`)
      .then((response) => response.data)

  restoreArchivedChat = (chatId: string) =>
    this.instance
      .get<{ success: boolean }>(`/chats-v2/${chatId}/restore-archived`)
      .then((response) => response.data)

  deleteArchivedChat = (chatId: string) =>
    this.instance
      .delete<{ success: boolean }>(`/chats-v2/${chatId}/delete-archived`)
      .then((response) => response.data)

  reportChatOwner = (chatId: string, reason: ReportReason) =>
    this.instance
      .post<{ success: boolean }>(`/chats-v2/${chatId}/report-owner`, {
        reason,
      })
      .then((response) => response.data)

  blockChatOwner = (chatId: string) =>
    this.instance
      .get<{ success: boolean }>(`/chats-v2/${chatId}/block-owner`)
      .then((response) => response.data)

  removeParticipant = (chatId: string, participantId: string) =>
    this.instance
      .delete<{ success: boolean }>(
        `/chat-participants/${chatId}/${participantId}`,
      )
      .then((response) => response.data)

  updateImage = (id: string, fileId: string) =>
    this.instance
      .put<{ success: boolean }>(`/chats-v2/${id}/update-image`, { fileId })
      .then((response) => response.data)

  deleteImage = (chatId: string) =>
    this.instance
      .delete<{ success: boolean }>(`/chats-v2/${chatId}/delete-image`)
      .then((response) => response.data)

  reportMessage = (id: string, reason: ReportReason) =>
    this.instance
      .post<{ success: boolean }>(`/chat-messages/${id}/report`, {
        reason,
      })
      .then((response) => response.data)
}
