import { useCallback, useContext, useEffect, useState } from 'react'
import { IChatV2, GetChatsParams } from '@/models/IChatV2'
import { IUser } from '@/models/IUser'
import { IPagination } from '@/models/IPagination'
import { ReportReason } from '@/models/IChatV2'
import { PusherContext } from '@/contexts/Pusher'
import useApi from './useApi'

const useInboxV2Chat = (chatId?: string) => {
  const { api, status } = useApi()

  const initialPagination = {
    totalPages: 0,
    page: 1,
    token: '',
    totalItems: 0,
    isLastPage: true,
  }

  const [pagination, setPagination] = useState<IPagination>(initialPagination)
  const [users, setUsers] = useState<IUser[]>([])
  const [loading, setLoading] = useState(false)
  const [chats, setChats] = useState<IChatV2[]>([])
  const { pusher } = useContext(PusherContext)
  const getUsers = useCallback(
    async (params: {
      text?: string
      limit?: number
      excludeChatId?: string
    }) => {
      if (status === 'loading') return

      setLoading(true)
      const response = await api.inboxV2
        .getNewChatUsers({
          text: params.text?.trim(),
          limit: params.limit || 20,
          excludeChatId: params.excludeChatId,
        })
        .catch((error) => {
          console.error('Failed to fetch users:', error)
          setUsers([])
          setPagination(initialPagination)
          return null
        })

      if (!response) {
        setLoading(false)
        return
      }
      if (params.text) {
        setPagination({
          ...initialPagination,
          token: response.pagination?.token || '',
        })
      }

      setUsers(response.users || [])
      setPagination(response.pagination || initialPagination)
      setLoading(false)

      return response
    },
    [api, status],
  )

  const createChat = useCallback(
    async (fromChatId: string | undefined, participants: string[]) => {
      try {
        const body = {
          fromChatId,
          participants,
        }

        const chat = await api.inboxV2.createChat(body)
        return chat
      } catch (error) {
        console.error('Failed to create chat:', error)
        throw error
      }
    },
    [api, pusher],
  )

  const getChats = useCallback(
    async (params: GetChatsParams & { status?: string }) => {
      console.log('getChats is calling')

      if (status === 'loading') return
      setLoading(true)
      const statusesToFetch = params.status
        ? [params.status]
        : ['accepted', 'pending', 'archived']

      const allChats: IChatV2[] = []
      let combinedPagination = initialPagination

      for (const statusToFetch of statusesToFetch) {
        const response = await api.inboxV2
          .getChats({
            limit: 20,
            ...params,
            status: statusToFetch as 'pending' | 'accepted' | 'archived',
          })
          .catch((error) => {
            console.error(
              `Failed to fetch chats for status "${statusToFetch}":`,
              error,
            )
            return null
          })

        if (response) {
          let chatsData: IChatV2[] = []

          if (response.items) {
            chatsData = response.items
          } else if (Array.isArray(response)) {
            chatsData = response
          }
          allChats.push(...chatsData)
          combinedPagination = response.pagination || initialPagination
        }
      }

      setChats(allChats)
      setPagination(combinedPagination)
      setLoading(false)

      return allChats
    },
    [api, status],
  )

  const archiveChat = useCallback(
    async (id: string) => {
      console.log('archiveChat is calling')
      if (status === 'loading') return

      setLoading(true)

      const response = await api.inboxV2.archiveChat(id).catch((error) => {
        setLoading(false)
        throw error
      })

      setLoading(false)
      return response
    },
    [api, status],
  )

  const restoreChat = useCallback(
    async (chatId: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .restoreArchivedChat(chatId)
        .catch((error) => {
          console.error('Failed to restore chat:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const deleteChat = useCallback(
    async (chatId: string) => {
      if (status === 'loading') return
      setLoading(true)
      try {
        setChats((prevChats: IChatV2[]) => {
          const updatedChats = prevChats.filter((chat) => chat.id !== chatId)
          return updatedChats
        })
        await api.inboxV2.deleteArchivedChat(chatId)
      } catch (error) {
        console.error('Failed to delete chat:', error)
      } finally {
        setLoading(false)
      }
    },
    [api, status],
  )
  const reportOwner = useCallback(
    async (chatId: string, reason: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .reportChatOwner(chatId, reason as unknown as ReportReason)
        .catch((error) => {
          console.error('Failed to report chat owner:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const reportMessage = useCallback(
    async (id: string, reason: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .reportMessage(id, reason as unknown as ReportReason)
        .catch((error) => {
          console.error('Failed to report chat owner:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const blockOwner = useCallback(
    async (chatId: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .blockChatOwner(chatId)
        .catch((error) => {
          console.error('Failed to block chat owner:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const removeParticipant = useCallback(
    async (chatId: string, participantId: string) => {
      if (loading) return false

      setLoading(true)

      try {
        const response = await api.inboxV2.removeParticipant(
          chatId,
          participantId,
        )
        setLoading(false)
        return response.success
      } catch (error) {
        throw error
      }
    },
    [api, loading],
  )

  const deleteImage = useCallback(
    async (chatId: string) => {
      if (loading) return false
      setLoading(true)
      try {
        const response = await api.inboxV2.deleteImage(chatId)
        setLoading(false)
        return response.success
      } catch (error) {
        setLoading(false)
        throw error
      }
    },
    [api, loading],
  )

  return {
    loading: status === 'loading' || loading,
    users,
    chats,
    getChats,
    archiveChat,
    getUsers,
    restoreChat,
    createChat,
    deleteChat,
    reportOwner,
    reportMessage,
    blockOwner,
    removeParticipant,
    deleteImage,
  }
}

export default useInboxV2Chat
